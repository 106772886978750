<template>
  <v-card>
    <v-card-title>
      {{ $t("msh.aside.appartments") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('msh.search')"
        single-line
        hide-details
        :loading-text="$t('msh.loading')"
        :loading="isLoading('/msh.service.v1.BuildingManager/GetFloors')"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="floor_appartments"
      :search="search"
      :custom-filter="customSearch"
      @click:row="navigateToDetail"
    >
      <template v-slot:item.presenceStatus="{ item }">
        <!-- i18n has traduction for each possible status (present, absent, unknown) -->
        {{ $t("msh.floor.presence." + item.presenceStatus) }}
      </template>

      <template v-slot:item.lastPresenceUpdated="{ item }">
        <!-- Extract date following defined i18n short option -->
        <span v-if="item.lastPresenceUpdated">
          {{ $d(new Date(item.lastPresenceUpdated.seconds) * 1000, "short") }}
        </span>
        <span v-else> {{ $t("msh.floor.presence.noPresence") }} </span>
      </template>

      <template v-slot:item.hasAlerts="{ item }">
        <v-chip :color="getHasAlertColor(item.stats)" dark>
          {{ labelHasAlert(item.stats) }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import router from "@/router";
import { FLOOR_GET_DEVICES_REQUEST } from "@/store/constants";

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("msh.dashboard.incidents.floor"),
          align: "start",
          sortable: true,
          value: "floor_name",
        },
        {
          text: this.$t("msh.dashboard.incidents.appartment"),
          value: "label",
          sortable: true,
        },
        {
          text: this.$t("msh.floor.presence.title"),
          value: "presenceStatus",
          sortable: true,
        },
        {
          text: this.$t("msh.floor.presence.lastSeen"),
          value: "lastPresenceUpdated",
        },
        { text: this.$t("msh.floor.alert"), value: "hasAlerts" },
      ],
    };
  },
  methods: {
    customSearch(value, search, item) {
      if (value != null && search != null && search.startsWith("presence:")) {
        // Handle unknown ?
        let presenceSearched = search.substring(9).split(",");
        // Check if multiple with ','
        if (presenceSearched.includes(item.presenceStatus.toString())) {
          return true;
        }
      }
      // if not with custom search do the regular search
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().includes(search)
      );
    },
    getHasAlertColor(stats) {
      if (stats && stats.totalAlerts && stats.totalAlerts > 0) {
        return "red";
      }
      return "green";
    },
    labelHasAlert(stats) {
      if (stats && stats.totalAlerts && stats.totalAlerts > 0) {
        return this.$t("msh.floor.alertActive");
      }
      return this.$t("msh.floor.noActiveAlert");
    },
    navigateToDetail(value) {
      router.push({
        name: "structureDetail",
        params: {
          buildingId: router.currentRoute.params.buildingId,
          structureId: value.structureId,
        },
      });
    },
  },
  computed: {
    ...mapState({
      floor_appartments: (state) => state.floors.floor_appartments,
    }),
    // mix the getters into computed with object spread operator
    ...mapGetters(["isLoading"]),
  },
  mounted() {
    const searchQuery = router.currentRoute.query.q;
    if (searchQuery) {
      this.search = searchQuery;
    }
  },
  async beforeRouteEnter(to, from, next) {
    store.dispatch(FLOOR_GET_DEVICES_REQUEST, to.params.buildingId);
    next();
  },
};
</script>
